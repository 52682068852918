<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Капиталы</h5>
    <Button
      label="Добавить запись"
      class="p-button-primary"
      @click="showCreateEditDialog(null)"
    />
    <DataTable
      :value="capitalList"
      data-key="id"
      v-model:wallets="capitalList"
      responsiveLayout="scroll"
      paginator
      lazy
      :rows="rows"
      :totalRecords="totalItems"
      :sortable="true"
      @page="setPage"
    >
      <Column header="Название" field="tokenBase.nameEng" />
      <Column header="Валюта" field="tokenQuote.nameEng" />
      <Column header="Максимум" field="maxCap">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data.maxCap"
            :decimals="slot.data.tokenQuote.decimals"
          />
        </template>
      </Column>
      <Column header="Минимум" field="maxCap">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data.minCap"
            :decimals="slot.data.tokenQuote.decimals"
          />
        </template>
      </Column>
      <Column header="Собрано" field="currentCap">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data.currentCap"
            :decimals="slot.data.tokenQuote.decimals"
          />
        </template>
      </Column>
      <Column header="Цена одного токена" field="priceBase">
        <template #body="slot">
          <OutputDecimals
            :decimals-value="slot.data.priceBase"
            :decimals="slot.data.tokenQuote.decimals"
          />
        </template>
      </Column>
      <Column header="Добавлен" field="listedAt">
        <template #body="slot">
          {{
            slot.data.listedAt
              ? moment(slot.data.listedAt).format('DD.MM.YYYY')
              : 'нет данных'
          }}
        </template>
      </Column>
      <Column class="bg-white border-none flex justify-content-end">
        <template #body="slot">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-warning m-1"
            @click="showCreateEditDialog(slot.data)"
          />
          <div data-help="Close">
            <Button
              icon="pi pi-lock"
              class="p-button-rounded p-button-warning m-1"
              @click="showCloseDialog(slot.data)"
            />
          </div>
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger m-1"
            @click="showDeleteDialog(slot.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible="createEditDialog"
    :header="header"
    style="width: 700px"
    class="p-fluid"
  >
    <TabView>
      <TabPanel header="Базовая информация">
        <div class="flex">
          <div class="col-6 flex-column">
            <label for="tokenbase">Название проекта</label>
            <Dropdown
              id="tokenbase"
              v-model="tokenBase"
              :options="tokenBaseList"
              option-label="name"
            />
            <div class="distance_top">
              <label id="user">Пользователь</label>
              <Dropdown
                id="user"
                v-model="userCapital"
                :options="userList"
                option-label="email"
              />
            </div>
            <div class="distance_top">
              <label for="mincap">Максимальный капитал</label>
              <InputDecimals
                id="mincap"
                v-model="maxcap"
                :decimals="decimals"
              />
            </div>
            <div class="distance_top">
              <label for="pricebase">Цена одного токена</label>
              <InputDecimals
                id="pricebase"
                v-model="priceBase"
                :decimals="decimals"
              />
            </div>
            <div class="distance_top">
              <label for="ratingfrom">Рейтинг from</label>
              <InputNumber id="ratingfrom" v-model="ratingFrom" />
            </div>
            <div class="distance_top">
              <label for="datestart">Дата начала</label>
              <Calendar id="datestart" v-model="dateStart" />
            </div>
            <div class="distance_top">
              <label for="section">Категория</label>
              <Dropdown
                id="section"
                v-model="section"
                :options="sectionList"
                option-label="name"
              />
            </div>
          </div>
          <div class="col-6">
            <label for="tokenquote">Валюта проека</label>
            <Dropdown
              id="tokenquote"
              v-model="tokenQuote"
              :options="tokenQuoteList"
              option-label="name"
              @change="decimals = tokenQuote.decimals"
            />
            <div class="distance_top">
              <label for="mincap">Минимальный капитал</label>
              <InputDecimals
                id="maxcap"
                v-model="mincap"
                :decimals="decimals"
              />
            </div>
            <div class="distance_top">
              <label for="currentcap">Текущий капитал</label>
              <InputDecimals
                id="currentcap"
                v-model="currentCap"
                :decimals="decimals"
              />
            </div>
            <div class="distance_top">
              <label for="listedat">Дата размещения</label>
              <Calendar id="listedat" v-model="listedAt" />
            </div>
            <div class="distance_top">
              <label for="ratingend">Рейтинг end</label>
              <InputNumber id="ratingend" v-model="ratingTo" />
            </div>
            <div class="distance_top">
              <label for="dateend">Дата конца</label>
              <Calendar id="dateend" v-model="dateEnd" />
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel v-if="isManager" header="Доп. информация">
        <div class="flex">
          <div class="col-6 flex-column">
            <label for="ispresale">Возможность предпродажи</label>
            <Dropdown
              id="ispresale"
              v-model="isPreSale"
              :options="presaleList"
              option-label="name"
            />
            <div class="distance_top">
              <label for="presaleprice">Цена предпродажи</label>
              <InputDecimals
                id="presaleprice"
                v-model="preSalePrice"
                :decimals="decimals"
              />
            </div>
            <div class="distance_top">
              <label for="presalemin">Обьем минимальной предпродажи</label>
              <InputDecimals
                id="presalemin"
                v-model="preSaleMinAmount"
                :decimals="decimals"
              />
            </div>
          </div>
          <div class="col-6">
            <label for="presaledao">Предпродажа DAO</label>
            <InputDecimals
              id="presaledao"
              v-model="preSaleDaoRequiredAmount"
              :decimals="decimals"
            />
            <div class="distance_top">
              <label for="presalepart">Частичная предпродажа</label>
              <InputDecimals
                id="presalepart"
                v-model="preSalePart"
                :decimals="decimals"
              />
            </div>
            <div class="distance_top">
              <label for="presalemax">Объем максимальной предпродажи</label>
              <InputDecimals
                id="presalemax"
                v-model="preSaleMaxAmount"
                :decimals="decimals"
              />
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel header="Описание">
        <Textarea
          id="description"
          v-model="description"
          style="min-height: 260px"
        />
      </TabPanel>
      <TabPanel header="Изображение">
        <FileUpload
          id="image"
          name="demo[]"
          accept="image/*"
          :customUpload="true"
          @select="select"
        />
      </TabPanel>
    </TabView>
    <small class="block m-1 p-error">
      <span v-if="!userChecked">Выберте пользователя</span>
      <span v-else-if="!allField">Заполните все поля</span>
      <span v-else-if="!isFiatExist">У пользователя нет выбранных токенов</span>
    </small>
    <div class="distance_top">
      <Button
        :disabled="!(allField && isFiatExist && userChecked)"
        :label="button"
        class="p-button-primary"
        @click="addOrEdit"
      />
    </div>
  </Dialog>

  <Dialog
    v-model:visible="closeDialog"
    header="Завершить капитал?"
    :modal="true"
  >
    <div class="confirmation-content">
      <p class="text-center p-error" v-if="itemEdit">
        Вы уверены, что хотите завершить?
      </p>
      <p class="text-center">
        <b>{{ modifyCapital.tokenBase.name }}</b>
      </p>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog = false"
      />
      <Button
        label="Да"
        icon="pi pi-check"
        class="p-button-text"
        @click="closeCapital"
      />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="deleteDialog"
    header="Удалить капитал?"
    :modal="true"
  >
    <div class="confirmation-content">
      <p class="text-center p-error" v-if="itemEdit">
        Вы уверены, что хотите удалить?
      </p>
      <p class="text-center">
        <b>{{ modifyCapital.tokenBase.name }}</b>
      </p>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteDialog = false"
      />
      <Button
        label="Да"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteCapital"
      />
    </template>
  </Dialog>
</template>

<script>
import moment from 'moment'
import OutputDecimals from '@/components/edit/OutputDecimals'
import InputDecimals from '@/components/edit/InputDecimals'

export default {
  name: 'Capital',
  components: { InputDecimals, OutputDecimals },
  data() {
    return {
      capitalList: [],
      tokenBaseList: [],
      tokenQuoteList: [],
      userList: [],
      sectionList: [],
      presaleList: [
        { name: 'Да', value: true },
        { name: 'Нет', value: false },
      ],
      userCapital: null,
      modifyCapital: null,
      createEditDialog: false,
      closeDialog: false,
      deleteDialog: false,
      tokenBase: null,
      tokenQuote: null,
      mincap: {
        decimalsValue: 0,
        displayValue: 0,
      },
      maxcap: {
        decimalsValue: 0,
        displayValue: 0,
      },
      decimals: 0,
      priceBase: {
        decimalsValue: 0,
        displayValue: 0,
      },
      currentCap: {
        decimalsValue: 0,
        displayValue: 0,
      },
      image: null,
      description: '',
      listedAt: '',
      ratingFrom: 0,
      ratingTo: 0,
      dateStart: '',
      dateEnd: '',
      section: null,
      isPreSale: { name: 'Нет', value: false },
      preSalePrice: {
        decimalsValue: 0,
        displayValue: 0,
      },
      preSalePart: {
        decimalsValue: 0,
        displayValue: 0,
      },
      preSaleMinAmount: {
        decimalsValue: 0,
        displayValue: 0,
      },
      preSaleMaxAmount: {
        decimalsValue: 0,
        displayValue: 0,
      },
      preSaleDaoRequiredAmount: {
        decimalsValue: 0,
        displayValue: 0,
      },
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  async mounted() {
    await this.getCapital()
    await this.getTokens()
    await this.getUsers()
    await this.getSection()
    this.isPreSale = this.presaleList[1]
  },
  computed: {
    tokenEquals() {
      if (this.tokenBase === null || this.tokenQuote === null) return false
      return this.tokenBase.id === this.tokenQuote.id
    },
    allField() {
      return (
        this.tokenBase !== null &&
        this.tokenQuote != null &&
        this.description.length > 0 &&
        this.priceBase.decimalsValue !== 0 &&
        this.maxcap.decimalsValue !== 0 &&
        this.section != null
      )
    },
    isEdit() {
      return this.modifyCapital === null
    },
    header() {
      return this.isEdit ? 'Добавить запись' : 'Редактировать запись'
    },
    button() {
      return this.isEdit ? 'Добавить' : 'Редактировать'
    },
    isFiatExist() {
      if (this.tokenQuote === null || this.userCapital === null) return false
      return (
        this.userCapital.tokens.find(
          (item) => item.id == this.tokenQuote.id
        ) !== undefined
      )
    },
    userChecked() {
      return this.userCapital !== null
    },
    isManager() {
      return this.$auth.user().type === 'manager'
    },
  },
  methods: {
    moment(date) {
      return moment(date)
    },
    setPage(event) {
      this.page = event.page + 1
      this.getCapital()
    },
    async getCapital() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/au_capitals', {
          headers,
          params,
        })
        const capitalData = resp.data['hydra:member']
        this.capitalList = capitalData
        this.totalItems = resp.data['hydra:totalItems']
      } catch (e) {
        this.showError('Error in au_capitals api call')
      }
    },

    async getTokens() {
      try {
        const resp = await this.axios.get('api/au_tokens')
        const tokenData = resp.data
        for (let i = 0; i < tokenData.length; i++) {
          const item = {}
          item.displayId = i
          item.id = tokenData[i].id
          item.name = tokenData[i].name
          item.decimals = tokenData[i].decimals
          switch (tokenData[i].type) {
            case 'asset':
              this.tokenBaseList.push(item)
              break
            case 'fiat':
              this.tokenQuoteList.push(item)
              break
          }
        }
      } catch (e) {
        this.showError('Error in au_tokens api call')
      }
    },

    async getUsers() {
      try {
        const resp = await this.axios.get('api/au_owners')
        const allOwner = resp.data
        for (const i of allOwner) {
          let el = this.userList.find((item) => item.id === i.user.id)
          if (el === undefined) {
            el = {}
            el.id = i.user.id
            el.email = i.user.email
            let id = i.auToken.id
            if (id === undefined) {
              id = i.auToken.slice(i.auToken.lastIndexOf('/') + 1)
            }
            el.tokens = new Array({ id: id, amountFree: i.amountFree })
            this.userList.push(el)
          } else {
            let id = i.auToken.id
            if (id === undefined) {
              id = i.auToken.slice(i.auToken.lastIndexOf('/') + 1)
            }
            el.tokens.push({ id: id, amountFree: i.amountFree })
          }
        }
      } catch (e) {
        this.showError('Error in au_owners api call')
      }
    },

    async getSection() {
      try {
        const resp = await this.axios.get('api/section_capitals')
        this.sectionList = resp.data
      } catch (e) {
        this.showError('Error in section_capitals api call')
      }
    },

    async addCapital() {
      this.createEditDialog = false
      try {
        const headers = {
          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        }

        let params = new FormData()
        params.append('tokenBase', `api/au_tokens/${this.tokenBase.id}`)
        params.append('tokenQuote', `api/au_tokens/${this.tokenQuote.id}`)
        params.append('tokenBaseId', this.tokenBase.id)
        params.append('tokenQuoteId', this.tokenQuote.id)
        params.append('minCap', this.mincap.decimalsValue)
        params.append('maxCap', this.maxcap.decimalsValue)
        params.append('priceBase', this.priceBase.decimalsValue)
        params.append('image', this.image?.name)
        params.append('imageFile', this.image, this.image?.name)
        params.append('description', this.description)
        params.append('listedAt', moment(this.listedAt))
        params.append('currentCap', this.currentCap.decimalsValue)
        params.append('ratingFrom', this.ratingFrom)
        params.append('ratingTo', this.ratingTo)
        params.append('dateStart', moment(this.dateStart))
        params.append('dateEnd', moment(this.dateEnd))
        params.append('section', `api/section_capitals/${this.section.id}`)
        params.append('isPreSale', this.isPreSale.value)
        params.append('preSalePrice', this.preSalePrice.decimalsValue)
        params.append('preSalePart', this.preSalePart.decimalsValue)
        params.append('preSaleMinAmount', this.preSaleMinAmount.decimalsValue)
        params.append('preSaleMaxAmount', this.preSaleMaxAmount.decimalsValue)
        params.append(
          'preSaleDaoRequiredAmount',
          this.preSaleDaoRequiredAmount.decimalsValue
        )

        const resp = await this.axios.post('api/au_capitals/post_mp', params, {
          headers: headers,
        })
        this.showNewCapital(resp.data)
        this.showMessage('New capital created')
      } catch (e) {
        this.showError('Error in created au_capital')
      }
    },

    showNewCapital(value) {
      const item = value
      item.displayId = this.capitalList.length
      item.displayTime =
        item.listedAt === undefined
          ? '-'
          : moment(item.listedAt).format('DD.MM.YYYY')
      this.capitalList.push(item)
    },

    showEditedCapital(value) {
      const item = value
      item.displayId = this.modifyCapital.displayId
      item.displayTime =
        item.listedAt === undefined
          ? '-'
          : moment(item.listedAt).format('DD.MM.YYYY')
      this.capitalList[this.modifyCapital.displayId] = item
    },

    clear() {
      this.tokenBase = null
      this.tokenQuote = null
      this.decimals = 0
      this.mincap.decimalsValue = 0
      this.mincap.displayValue = 0
      this.maxcap.decimalsValue = 0
      this.maxcap.displayValue = 0
      this.priceBase.decimalsValue = 0
      this.priceBase.displayValue = 0
      this.currentCap.decimalsValue = 0
      this.currentCap.displayValue = 0
      this.image = null
      this.description = ''
      this.listedAt = ''
      this.userCapital = null
      this.ratingFrom = 0
      this.ratingTo = 0
      this.dateStart = ''
      this.dateEnd = ''
      this.section = null
      if (this.isManager) {
        this.isPreSale = this.presaleList[1]
        this.preSalePrice.decimalsValue = 0
        this.preSalePrice.displayValue = 0
        this.preSalePart.decimalsValue = 0
        this.preSalePart.displayValue = 0
        this.preSaleMinAmount.decimalsValue = 0
        this.preSaleMinAmount.displayValue = 0
        this.preSaleMaxAmount.decimalsValue = 0
        this.preSaleMaxAmount.displayValue = 0
        this.preSaleDaoRequiredAmount.decimalsValue = 0
        this.preSaleDaoRequiredAmount.displayValue = 0
      }
    },

    edit(value) {
      this.tokenBase = this.tokenBaseList.find(
        (item) => item.id === value.tokenBase.id
      )
      this.tokenQuote = this.tokenQuoteList.find(
        (item) => item.id === value.tokenQuote.id
      )
      this.decimals = value.tokenQuote.decimals
      this.mincap.decimalsValue = value.minCap
      this.maxcap.decimalsValue = value.maxCap
      this.priceBase.decimalsValue = value.priceBase
      this.currentCap.decimalsValue = value.currentCap
      this.description = value.description ? value.description : ''
      this.listedAt = value.listedAt
        ? moment(value.listedAt).format('DD.MM.YYYY')
        : ''
      this.userCapital = null
      this.ratingFrom = value.ratingFrom ? value.ratingFrom : ''
      this.ratingTo = value.ratingTo ? value.ratingTo : ''
      this.dateStart = moment(value.dateStart).format('DD.MM.YYYY')
      this.dateEnd = moment(value.dateEnd).format('DD.MM.YYYY')
      this.section = this.sectionList.find((item) => {
        const slashPos = String(value.section).lastIndexOf('/') + 1
        return item.id == String(value.section).slice(slashPos)
      })
      if (this.isManager) {
        this.isPreSale =
          value.isPreSale === null || value.isPreSale === undefined
            ? this.presaleList[1]
            : this.presaleList.find((item) => item.value === value.isPreSale)
        this.preSalePrice.decimalsValue = this.checkValue(value.preSalePrice)
        this.preSalePart.decimalsValue = this.checkValue(value.preSalePart)
        this.preSaleMinAmount.decimalsValue = this.checkValue(
          value.preSaleMinAmount
        )
        this.preSaleMaxAmount.decimalsValue = this.checkValue(
          value.preSaleMaxAmount
        )
        this.preSaleDaoRequiredAmount.decimalsValue = this.checkValue(
          value.preSaleDaoRequiredAmount
        )
      }
    },

    showCreateEditDialog(value) {
      this.modifyCapital = value
      if (value === null) this.clear()
      else this.edit(value)
      this.createEditDialog = true
    },

    addOrEdit() {
      if (this.modifyCapital === null) this.addCapital()
      else this.editCapital()
    },
    showCloseDialog(value) {
      this.modifyCapital = value
      this.closeDialog = true
    },
    showDeleteDialog(value) {
      this.modifyCapital = value
      this.deleteDialog = true
    },
    closeCapital() {
      this.closeDialog = false
      let params = {
        user: `/api/users/${this.$auth.user().id}`,
        auCapital: `/api/au_capitals/${this.modifyCapital.id}`,
        type: 'token_capital_close',
        description: '',
        value: '0',
        address: '',
        isTask: true,
      }
      try {
        this.axios.post('api/token_events', params)
        this.showMessage('Capital closed successfully')
      } catch (e) {
        this.showError('Error in closed au_capital')
      }
    },
    deleteCapital() {
      this.deleteDialog = false
      try {
        this.axios.delete(`api/au_capitals/${this.modifyCapital.id}`)
        this.capitalList.splice(this.modifyCapital.displayId, 1)
        this.showMessage('Capital deleted successfully')
      } catch (e) {
        this.showError('Error in deleted au_capital')
      }
    },

    select(event) {
      this.image = event.files[0]
    },

    async editCapital() {
      try {
        const headers = {
          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        }

        let params = new FormData()
        params.append('tokenBase', `api/au_tokens/${this.tokenBase.id}`)
        params.append('tokenQuote', `api/au_tokens/${this.tokenQuote.id}`)
        params.append('id', this.modifyCapital.id)
        params.append('tokenBaseId', this.tokenBase.id)
        params.append('tokenQuoteId', this.tokenQuote.id)
        params.append('minCap', this.mincap.decimalsValue)
        params.append('maxCap', this.maxcap.decimalsValue)
        params.append('priceBase', this.priceBase.decimalsValue)
        params.append('image', this.image?.name)
        params.append('imageFile', this.image, this.image?.name)
        params.append('description', this.description)
        params.append('listedAt', moment(this.displayTime).format('DD.MM.YYYY'))
        params.append('currentCap', this.currentCap.decimalsValue)
        params.append('ratingFrom', this.ratingFrom)
        params.append('ratingTo', this.ratingTo)
        params.append('dateStart', moment(this.dateStart))
        params.append('dateEnd', moment(this.dateEnd))
        params.append('section', `api/section_capitals/${this.section.id}`)
        params.append('isPreSale', this.isPreSale.value)
        params.append('preSalePrice', this.preSalePrice.decimalsValue)
        params.append('preSalePart', this.preSalePart.decimalsValue)
        params.append('preSaleMinAmount', this.preSaleMinAmount.decimalsValue)
        params.append('preSaleMaxAmount', this.preSaleMaxAmount.decimalsValue)
        params.append(
          'preSaleDaoRequiredAmount',
          this.preSaleDaoRequiredAmount.decimalsValue
        )

        const resp = await this.axios.post(`api/au_capitals/post_mp`, params, {
          headers: headers,
        })
        this.createEditDialog = false
        this.showEditedCapital(resp.data)
        this.showMessage('Capital edited successfully')
      } catch (e) {
        this.showError('Error in edited au_capital')
      }
    },

    showError(message) {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: message,
        group: 'info',
        life: 3000,
      })
    },

    showMessage(message) {
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: message,
        group: 'info',
        life: 3000,
      })
    },

    checkValue(value) {
      if (value === '' || value === null) return 0
      return value
    },
  },
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: 7px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
.distance_top {
  margin-top: 7px;
}
</style>
