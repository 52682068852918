<template>
  <InputNumber
    :id='id'
    :modelValue='modelValue.displayValue'
    :class='{"p-invalid" : isExceed}'
    mode="decimal"
    locale="en-US"
    :useGrouping='false'
    :maxFractionDigits="decimals"
    @input='updateDisplay'
  />
</template>

<script>
export default {
  name: 'InputDecimals',
  props:{
    modelValue: {
      type: Object
    },
    id:{
      type: String
    },
    decimals:{
      type: Number,
      default: 0
    },
    maxValue: {
      type: String
    }
  },
  mounted()
  {
    if (this.modelValue.decimalsValue !== undefined)
    {
      const param = {}
      if (String(param.decimalsValue).length < this.decimals) {
        param.decimalsValue = "0".repeat(this.decimals - String(param.decimalsValue).length + 1) +
          this.modelValue.decimalsValue;
      }
      else{
          param.decimalsValue = this.modelValue.decimalsValue
      }
      if (this.decimals !== 0)
      {
        param.displayValue = String(param.decimalsValue).slice(0, -this.decimals) + '.' +
          String(param.decimalsValue).slice(-this.decimals)
      }
      else param.displayValue = param.decimalsValue
      this.$emit("update:modelValue", param)
    }
  },
  computed: {
    isExceed() {
      if (this.maxValue === undefined || this.maxValue === null) return false;
      // eslint-disable-next-line no-undef
      return BigInt(this.modelValue.decimalsValue) > this.maxValue;
    }
  },
  methods:{
    updateDisplay(event)
    {
      const param = {}
      param.displayValue = event.value
      const dotPos = String(event.value).lastIndexOf('.')
      if (dotPos !== -1) {
        param.decimalsValue = String(event.value).slice(0, dotPos) +
          String(event.value).slice(dotPos + 1)
        const missAfterZeroDigits = this.decimals - (String(param.displayValue).length - dotPos - 1)
        if (missAfterZeroDigits > 0) {
          param.decimalsValue = param.decimalsValue + "0".repeat(missAfterZeroDigits);
        }
      }
      else param.decimalsValue = event.value + '0'.repeat(this.decimals)
      this.$emit("update:modelValue", param)
    },
  }
}
</script>

<style scoped>

</style>